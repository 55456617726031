import SocialIcons from "../social-icons";

const Footer = props => {

    return (
        <div className='footer-container'>

            <p>#StandWithBelarus</p>
            <div className="mt-20">
                <SocialIcons />
            </div>

        </div>
    )
}

export default Footer;
