import React, { Component } from "react";
import {withGoogleMap, GoogleMap, Marker} from "react-google-maps"
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer"

const centerAddress = { lat: 52.9006011, lng: 13.558972 }

const FormGoogleMap = withGoogleMap((props) => {

  return (
    <GoogleMap
      defaultCenter={props.geo}
      defaultZoom={2}
      defaultOptions={{
        maxZoom: 18,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false}}
    >
      {props.children}
    </GoogleMap>
  );
});

export default class FormMap extends Component {

  render() {

    let points = []
    let pointsHeatMap = this.props.diasporasEvents.map(item => {
        return {lat: Number(item.lat), lng: Number(item.lng), weight:1}
    })

    if (this.props.diasporas) {
        points = this.props.diasporasEvents.map((item, index) =>
            <Marker
                clickable={false}
                key={index}
                position={{ lat: Number(item.lat), lng: Number(item.lng) }}
            />);
    } else {
        points = this.props.points.map((item, index) =>
            <Marker
                clickable={false}
                key={index}
                position={{ lat: Number(item.center.x), lng: Number(item.center.y) }}
            />);
    }


    return (

        <FormGoogleMap
            geo={centerAddress}
            loadingElement={<div style={{ height: `100%`, width: `100%` }} />}
            containerElement={<div style={{ height: `400px`, width: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}>

            <MarkerClusterer
                averageCenter
                enableRetinaIcons
            >
                {points}
            </MarkerClusterer>

        </FormGoogleMap>
    )
  }
}
