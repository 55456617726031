import React, { Component } from 'react';

import "./main.css";
import "./shortcuts.css";

import Header from "../header";
import Counter from "../counter-block";
import Map from "../map/map";
import Footer from "../footer-bottom";
import TextSection from "../text-section";
import WhyMatters from "../why-matters";
import { saveGeolocation, fetchCounters, checkUsedStatus } from "../../actions";
import {connect} from "react-redux";

const geolocationOptions = {
  enableHighAccuracy: true,
  timeout: 1000 * 60, // 1 min (1000 ms * 60 sec * 1 minute = 60 000ms)
  maximumAge: 1000 * 3600 * 24, // 24 hour
};

class App extends Component {

  componentDidMount() {

    this.props.fetchCounters();
    this.props.checkUsedStatus();

    const { geolocation } = navigator;

    if (!geolocation) {
      console.log("Geolocation is not supported.");
      return;
    }

    geolocation.getCurrentPosition(
        position => {
        const {latitude, longitude} = position.coords;
        console.log(`Geolocation detected: ${latitude} ${longitude}`)
        this.props.saveGeolocation(latitude, longitude)
      },
      error => console.log(error),
      geolocationOptions)

  }

  render () {
    return (
        <div className="wrapper">
          <div className="container">
            <Header/>
            <div className="separator"/>
            <div className="text-section">
              <h3>FEBRUARY 7 IS THE DAY OF SOLIDARITY WITH BELARUS</h3>
            </div>
            <div className="separator"/>
            <Counter/>
            <div className="separator"/>
            <Map/>
            <div className="separator"/>
            <div className="text-section">
              <h3>USE HASHTAG #StandWithBelarus</h3>
            </div>
            <div className="separator"/>
            <TextSection/>
            <div className="separator"/>
            <WhyMatters/>
            <div className="separator"/>
            <Footer/>
          </div>
        </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    saveGeolocation: saveGeolocation(dispatch),
    fetchCounters: fetchCounters(dispatch),
    checkUsedStatus: checkUsedStatus(dispatch),
  };
};

export default connect(null, mapDispatchToProps)(App);
