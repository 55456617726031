
const TextSection = props => {

    return (
        <div className='flex2col'>
            <div className="left">
                <div className='p-30'>
                    <h3>SOLIDARITY WITH BELARUS</h3>
                    <p className='mt-20'>To support those who protest daily in Belarus in the face of adversity and thank them for their bravery and strong will, the leader of democratic Belarus Sviatlana Tsikhanouskaya proclaimed Sunday, February 7th as the Day of Solidarity with Belarus. This day will mark six months of peaceful protests in Belarus.</p>
                    <p className='mt-20'>Since the fraudulent presidential election of August 9th, 2020, Belarusians have demanded democracy and freedom, staying persistent and courageous in spite of the regime's lawlessness. While the ruthless dictatorship resorted to unmatched violence, the protest has been peaceful and resourceful, revealing the vast creative potential of Belarusians.</p>
                </div>

            </div>
            <div className="right">
                <a className="twitter-timeline" data-width="100%" data-height="400" data-theme="light"
                   href="https://twitter.com/Tsihanouskaya?ref_src=twsrc%5Etfw">Tweets by Tsihanouskaya</a>
            </div>
        </div>
    )
}

export default TextSection
