import { v4 as uuidv4 } from 'uuid';

const SERVER_URL = process.env.REACT_APP_API_URL ?
    process.env.REACT_APP_API_URL : "";

const COUNTERS_URL = process.env.REACT_APP_STATS_BUCKET_URL ?
    process.env.REACT_APP_STATS_BUCKET_URL : "";

export default class APIService {

  _points = [
    {
      "hash": "d8w6",
      "center": {
        "x": -58.53515625,
        "y": 3.251953125
      },
      "count": 3
    },
    {
      "hash": "dr4x",
      "center": {
        "x": -75.05859375,
        "y": 40.693359375
      },
      "count": 2
    },
    {
      "hash": "dr5r",
      "center": {
        "x": -74.00390625,
        "y": 40.693359375
      },
      "count": 1
    },
  ]

  getAsyncFetch = (urladdon, requestOptions={}, serverToLoad=SERVER_URL, isJSON=true) => {

    console.log("requesting data from: " + (serverToLoad + urladdon));

    return fetch(serverToLoad + urladdon, requestOptions)
        .then(res => {
          if (res.status === 200 || res.status === 201) {
            return isJSON ? res.json() : res.text();
          } else {
            Promise.reject(res.statusText);
          }
        })
        .then(res => {
          if(res === undefined) {
            Promise.reject("Произошла ошибка загрузки данных. Мы обязательно исправим ее в ближайшее время");
          }
          return Promise.resolve(res)
        })
        .catch(error => {
          Promise.reject(error);
        })
  }

  getPoints = async (offset=0, limit=1000) => {

    const coordinates = "?sw=-90,-180&ne=90,180";
    const page = `&limit=${limit}&offset=${offset}`;

    const requestOptions = {
      method: 'GET',
    }

    return this.getAsyncFetch("/checkin/clusters" + coordinates + page, requestOptions);
  };

  checkInUser = async (lat, lng) => {

    const userId = uuidv4();
    const location = {
      location: {
        latitude: lat,
        longitude: lng
      }
    }

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-user': userId,
      },
      body: JSON.stringify(location),
    }

    return this.getAsyncFetch("/checkin", requestOptions, SERVER_URL, false);
  };

  getCounters = async () => {

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    return this.getAsyncFetch("", requestOptions, COUNTERS_URL);
  };

}
