import SupportButton from "../support-button/support-button";

const WhyMatters = props => {

    return (
        <div className='flex2col' style={{backgroundColor: "#F3F3F3"}}>
            <div className="left">
                <div className="d-flex-center hp-100 pt-30 pb-30">
                    <SupportButton />
                </div>
            </div>
            <div className="right">
                <div className='p-30'>
                    <h3>WHY IT MATTERS</h3>
                    <p className='mt-20'>Last year, the people of Belarus began protesting against the rigged election results, lawlessness, and police violence. There were hundreds of thousands of peaceful Belarusians in the streets, the creative female protests, the students singing in the universities, strikers applauding in the factories, astonishing rallies of the elderly and people with special needs, the parties of the neighbors, and so on. The whole world saw it.</p>

                    <p className='mt-20'>Now the picture is somewhat different. It's harder to find the photos of beautiful mass rallies in Minsk on the international newspapers' covers, as was the case recently. Does it mean that the protests have finished? No, it does not. Belarusians have been peacefully protesting all across the country every single day for half a year already.</p>
                   </div>
            </div>
        </div>
    )
}

export default WhyMatters
