import React, {Component} from 'react';
import FormMap from "./form-map";
import { connect } from "react-redux";
import {fetchPoints} from "../../actions";

const diasporasEvents = [
    {heading: "Germany/Frankfurt", text: "Solidarity Rally", lat: 50.041821, lng: 8.584764},
    {heading: "Germany/Bonn", text: "Solidarity Event", lat: 50.724104, lng: 7.114887},
    {heading: "Germany/Berlin", text: "Solidarity Event", lat: 52.52045, lng: 13.40732, url:'https://www.facebook.com/events/422127275703814'},
    {heading: "Germany/Berlin", text: "Prayer for Belarus in Gethsemane-Kirche", lat: 52.52045, lng: 13.40732},
    {heading: "Germany/Göttingen", text: "Solidarity Event", lat: 51.543046, lng: 9.93664},
    {heading: "Germany/Ravensburg", text: "Solidarity Event", lat: 47.783953, lng: 9.612955},
    {heading: "Germany/Hamburg", text: "Solidarity Rally", lat: 53.576158, lng: 10.007046},
    {heading: "Germany/Bremen", text: "Solidarity Event", lat: 53.029599, lng: 8.731851},
    {heading: "Germany/Bremen", text: "White Red White Illumination of Theater Bremen", lat: 53.029599, lng: 8.731851},
    {heading: "Germany/Stuttgart", text: "Solidarity Event", lat: 48.802275, lng: 9.188624},
    {heading: "Germany/Munich", text: "Solidarity Event", lat: 48.152126, lng: 11.544467},
    {heading: "Germany/Dresden", text: "Solidarity Event", lat: 51.055604, lng: 13.731021},
    {heading: "Germany/Hannover", text: "Solidarity Event", lat: 52.379952, lng: 9.787455},
    {heading: "United Kingdom/London", text: "Video flash mob", lat: 51.509648, lng: -0.099076},
    {heading: "Belgium/Brussels", text: "Solidarity Event", lat: 50.733183, lng: 4.436117},
    {heading: "Netherlands/Hague", text: "Solidarity Event", lat: 52.07114, lng: 4.29916},
    {heading: "Finland/Helsinki", text: "Solidarity Event", lat: 60.20787, lng: 24.99935},
    {heading: "France/Paris", text: "Solidarity Event", lat: 48.859116, lng: 2.331839},
    {heading: "France/Clermont Ferrand", text: "White-red-white flag on a city building", lat: 45.784832, lng: 3.114432},
    {heading: "France/Lyon", text: "Solidarity Rally", lat: 45.752181, lng: 4.840823},
    {heading: "France/Annecy", text: "Solidarity Marathon", lat: 45.906143, lng: 6.125034},
    {heading: "France/Nice", text: "Solidarity Rally", lat: 43.713667, lng: 7.264625},
    {heading: "France/Marseille", text: "Solidarity Marathon", lat: 43.295314, lng: 5.401581},
    {heading: "France/Strasbourg", text: "Solidarity Event and illumination in the city", lat: 48.585505, lng: 7.733206},
    {heading: "France/Bordeaux", text: "Solidarity Event", lat: 44.830764, lng: -0.69142},
    {heading: "Sweden/Malmö", text: "Cultural event", lat: 55.59438, lng: 13.029953},
    {heading: "Sweden/Malmö", text: "White-red-white flags in the city", lat: 55.59438, lng: 13.029953},
    {heading: "Sweden/Stockholm", text: "White-red-white flags and illumination in the city", lat: 59.275254, lng: 18.035164},
    {heading: "Sweden/Stockholm", text: "Solidarity Event", lat: 59.275254, lng: 18.035164},
    {heading: "USA/Boston", text: "Solidarity Event", lat: 42.355492, lng: -71.048611, url:'https://www.facebook.com/events/905665293508795'},
    {heading: "USA/Philadelphia", text: "Solidarity Event", lat: 39.990821, lng: -75.168428, url:'https://www.facebook.com/events/471556310876472/'},
    {heading: "USA/Denver", text: "Solidarity Event", lat: 39.740959, lng: -104.985798, url:'https://www.facebook.com/events/1096120527569539/'},
    {heading: "USA/Seattle", text: "Solidarity Event", lat: 47.673455, lng: -122.331537},
    {heading: "USA/Pittsburgh", text: "Solidarity Event", lat: 33.726433, lng: -84.402083, url:'https://fb.me/e/6bpNh0VUw'},
    {heading: "USA/New York", text: "Cultural Event", lat: 40.68295, lng: -73.9708, url:'https://www.facebook.com/events/763968307885527/'},
    {heading: "USA/San Diego", text: "Solidarity Event", lat: 32.72793, lng: -117.15529, url:'https://www.facebook.com/events/463734808345195/'},
    {heading: "USA/San Francisco", text: "Solidarity Event", lat: 37.787519, lng: -122.413835, url:'https://www.facebook.com/events/948380115692943/'},
    {heading: "USA/Miami", text: "Solidarity Event", lat: 25.766368, lng: -80.210268, url:'https://www.facebook.com/events/455895092487951/'},
    {heading: "USA/Portland", text: "Solidarity Event", lat: 25.766368, lng: -80.210268, url:'https://www.facebook.com/events/1641969355990998/'},
    {heading: "Portugal/Lisbon", text: "Solidarity Event", lat: 38.748243, lng: -9.140093, url:'https://www.facebook.com/events/870705410417939'},
    {heading: "Switzerland/Geneva", text: "Solidarity Marathon", lat: 46.191574, lng: 6.131497},
    {heading: "Austria/Vienna ", text: "Solidarity Rally", lat: 48.198674, lng: 16.348388, url:'https://fb.me/e/19Ljp9cgl'},
    {heading: "South Korea/Seoul", text: "Online Event", lat: 37.539619, lng: 127.009676},
    {heading: "Poland/Warsaw", text: "Solidarity Event", lat: 52.237695, lng: 21.005427},
    {heading: "Poland/Białystok", text: "Solidarity Event", lat: 53.138198, lng: 23.173944},
    {heading: "Israel/Tel Aviv", text: "Solidarity Rally", lat: 32.072773, lng: 34.804786},
    {heading: "Lithuania/Vilnius", text: "Solidarity Event", lat: 54.700902, lng: 25.251531},
    {heading: "Romania/Bucharest", text: "Solidarity Event", lat: 44.435842, lng: 26.085733},
    {heading: "Moldova/Kishinev", text: "Solidarity Event", lat: 47.020682, lng: 28.854719},
    {heading: "Latvia/Riga", text: "Solidarity Event", lat: 56.971149, lng: 24.142749},
    {heading: "Czechia/Olomouc", text: "Solidarity Event", lat: 49.59372, lng: 17.260292, url: "https://www.facebook.com/events/262004245310073"},
    {heading: "Czechia/Prague", text: "Solidarity Event", lat: 50.06694, lng: 14.460249, url: "https://www.facebook.com/events/1499123847105527/"},
    {heading: "Estonia/Tallinn", text: "Solidarity Event", lat: 59.401347, lng: 24.693912, url: "https://www.facebook.com/events/920731308749274/"},
    {heading: "Estonia/Tartu", text: "Solidarity Event", lat: 58.373824, lng: 26.723421, url: "https://www.facebook.com/events/219504549877440/"},
    {heading: "Russia/Saint Petersburg", text: "Solidarity Event", lat: 59.911039, lng: 30.340232},
    {heading: "Russia/Moscow", text: "Solidarity Event", lat: 55.741469, lng: 37.615561},
    {heading: "Ukraine/Kiev", text: "Solidarity Rally and illumination in the city ", lat: 50.469863, lng: 30.431971},
    {heading: "Brazil/Brasília", text: "Solidarity Event and Cultural Event", lat: -15.803598, lng: -47.903099},
    {heading: "Italy/Bologna", text: "Solidarity Event", lat: 44.498216, lng: 11.426021},
    {heading: "Italy/Milan", text: "Solidarity Event", lat: 45.473702, lng: 9.170685},
    {heading: "Italy/Rome", text: "Solidarity Event", lat: 41.878243, lng: 12.52809},
    {heading: "Italy/Padua", text: "Solidarity Event", lat: 45.410418, lng: 11.879661},
    {heading: "Canada/Calgary", text: "Solidarity Event", lat: 51.056508, lng: -114.033822, url:'https://www.facebook.com/events/722199465003031/'},
    {heading: "Canada/Edmonton", text: "Solidarity Event", lat: 53.546218, lng: -113.490371, url:'https://www.facebook.com/events/258057325755946/'},
    {heading: "Canada/Montreal", text: "Solidarity Event", lat: 45.572744, lng: -73.586295, url:'https://www.facebook.com/events/258540245668305/'},
    {heading: "Canada/Ottawa", text: "Solidarity Event", lat: 45.209415, lng: -75.783876, url:'https://www.facebook.com/events/326170645454637/'},
    {heading: "Canada/Regina", text: "Solidarity Event", lat: 50.448205, lng: -104.619116, url:'https://www.facebook.com/events/758769001724817/'},
    {heading: "Canada/Toronto", text: "Solidarity Event", lat: 43.64877, lng: -79.38171, url:'https://www.facebook.com/events/756454651920635/'},
    {heading: "Canada/Vancouver", text: "Solidarity Event", lat: 49.2819, lng: -123.11874, url:'https://www.facebook.com/events/421200015999409 '},
    {heading: "Canada/Winnipeg", text: "Solidarity Event", lat: 49.874557, lng: -97.138026},
]

class Map extends Component {

    componentDidMount() {
        this.props.fetchPoints();
    }

    render () {

        const { points } = this.props;

        return (

            <div>
                <div>
                    <FormMap
                        diasporasEvents={diasporasEvents}
                        diasporas={true}
                        points={points}
                    />
                </div>
                <div className="mt-20">
                    <div className="d-flex-wrap p-15">

                        {
                            diasporasEvents.map((item, index) =>
                                <div key={index} className="activity">

                                    {item.url &&
                                        <a href={item.url} target="_blank" rel="noreferrer"><strong>{item.heading}</strong></a>
                                    }
                                    {item.url === undefined &&
                                        <strong>{item.heading}</strong>
                                    }
                                    <p>{item.text}</p>
                                </div>
                            )
                        }

                    </div>

                </div>
            </div>

        );
    }

}


const mapStateToProps = ({ data: { points, loading, error }}) => {
    return { points, loading, error };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchPoints: fetchPoints(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);

