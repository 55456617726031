import logo from "../../assets/sdlogo.png";
import SocialIcons from "../social-icons";
import {checkInUser} from "../../actions";
import {connect} from "react-redux";
import SupportButton from "../support-button/support-button";

const Header = props => {

    return (
        <div className='header-wrapper'>
            <div className="header-container">
                <div className="mr-20 mb-20">
                    <img className="header-logo" src={logo} alt="logo" />
                </div>
                <div className="mr-20 mb-20">
                    <SocialIcons />
                    <p className="mt-10">#StandWithBelarus</p>
                </div>
                <div className="mb-20">
                    {props.geolocation &&
                       <SupportButton />
                    }
                    {props.geolocation === null &&
                    <div>
                        <div className="fs-9 mt-10 ta-right">DETECTING GEOLOCATION...</div>
                        <div className="fs-9 mt-10 ta-right">ENABLE GEOLOCATION IN YOUR BROWSER TO CHECKIN</div>
                    </div>
                    }
                </div>
            </div>

        </div>
    )
}

const mapStateToProps = ({ data: { geolocation }}) => {
    return { geolocation };
};

const mapDispatchToProps = (dispatch) => {
    return {
        checkInUser: checkInUser(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
