
import {checkInUser} from "../../actions";
import {connect} from "react-redux";

const SupportButton = props => {

    const checkIn = () => {
        if (props.geolocation) {
            props.checkInUser(props.geolocation.lat, props.geolocation.lng)
        }
    }

    const counter = (props.counters && props.counters.SupportersAbroad) ? props.counters.SupportersAbroad : 0

    const liItems = [];
    Array.from(counter.toString()).map((item, index) =>
        liItems.push(<li key={index}>{item}</li>))

    const counterComponent = liItems.length > 0 ?
        <ul>{liItems}</ul> : null;

    const date = new Date()
    const isDate = (date.getMonth() === 1 && (date.getDate() >= 5 && date.getDate() <= 7))

    const btnEnabled = (isDate && props.geolocation !== null && !props.used)

    return (

        <div>
            {props.used &&
                <div className="counter">
                    {counterComponent}
                    <div className="fs-12 mt-10 ta-center">PEOPLE SUPPORT BELARUS</div>
                </div>
            }
            {!props.used &&
                <button disabled={!btnEnabled} style={{opacity: btnEnabled ? 1 : 0.3, cursor: btnEnabled ? "pointer" : "auto"}} onClick={() => checkIn()}>I SUPPORT BELARUS</button>
            }
            {(!props.used && !isDate) &&
            <div className="fs-9 mt-10 ta-center">YOU CAN ACTIVATE THIS BUTTON ON FEB 6-7</div>
            }
            {(!props.used && isDate) &&
            <div className="fs-9 mt-10 ta-center">PRESS TO SHARE YOUR SOLIDARITY</div>
            }

        </div>

    )
}

const mapStateToProps = ({ data: { geolocation, used, counters }}) => {
    return { geolocation, used, counters };
};

const mapDispatchToProps = (dispatch) => {
    return {
        checkInUser: checkInUser(dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportButton);
