
const updateData = (state, action) => {

    if (state === undefined) {
        return {
            points: [],
            loading: false,
            error: false,
            geolocation: null,
            counters: {},
            used: false
        };
    }

    switch (action.type) {
        case 'FETCH_DATA_REQUEST':
            return {
                ...state.data,
                loading: true,
            };

        case 'FETCH_DATA_SUCCESS':
            return {
                ...state.data,
                points: action.payload,
                loading: false,
            };

        case 'FETCH_DATA_FAILURE':
            return {
                points: [],
                loading: false,
                error: action.payload
            };

        case 'GEOLOCATION_DETECTED':
            return {
                ...state.data,
                geolocation: action.payload,
            };

        case 'FETCH_COUNTERS_SUCCESS':
            return {
                ...state.data,
                counters: action.payload
            };

        case 'USED_UPDATE':
            return {
                ...state.data,
                used: true
            };

        default:
            return state.data;
    }
};

export default updateData;
