import counterImage from "../../assets/sdmap.jpg";
import {connect} from "react-redux";

const Counter = props => {

    return (
        <div className='flex2col'>
            <div className="left" style={{height: 350}}>
                <iframe title="yuotube" width="100%" height="100%" src="https://www.youtube.com/embed/pePji-4H5D0" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen />
            </div>
            <div className="right">
                <div style={{position: "relative"}}>
                    <div style={{height: 350}}>
                        <img width="100%" height="100%" style={{objectFit: "cover"}} src={counterImage} alt="counter" />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ data: { counters }}) => {
    return { counters };
};


export default connect(mapStateToProps)(Counter);
