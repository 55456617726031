import fb from "../../assets/ico_fb.svg";
import insta from "../../assets/ico_insta.svg";
//import ok from "../../assets/ico_ok.svg";
import tg from "../../assets/ico_tg.svg";
//import vk from "../../assets/ico_vk.svg";
//import viber from "../../assets/ico_viber.svg";
import twitter from "../../assets/ico_twitter.svg";

const DEFAULT_ICONS = [
    {icon:fb, url:"https://www.facebook.com/S.Tsikhanouskaya"},
    {icon:twitter, url:"https://twitter.com/Tsihanouskaya"},
    {icon:insta, url:"https://www.instagram.com/prezident.sveta/"},
    {icon:tg, url:"https://t.me/tsikhanouskaya"},
    /*
    {icon:vk, url:"http://google.com"},
    {icon:ok, url:"http://google.com"},
    {icon:viber, url:"http://google.com"},
     */
]

const SocialIcons = props => {

    const icons = props.icons ? props.icons : DEFAULT_ICONS

    return (
        <div className='social-icons'>
            <ul>
                {
                    icons.map((item, index) => {
                        return (
                            <li key={index}><a href={item.url} target="_blank" rel="noreferrer"><img src={item.icon} alt="social-icon"/></a></li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default SocialIcons
