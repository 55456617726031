import APIService from "../services/api-service";
import Cookies from 'universal-cookie';

const pointsRequested = () => {
    return {
        type: 'FETCH_DATA_REQUEST'
    }
};

const pointsLoaded = (data) => {
    return {
        type: 'FETCH_DATA_SUCCESS',
        payload: data
    };
};

const pointsError = (error) => {
    return {
        type: 'FETCH_DATA_FAILURE',
        payload: error
    };
};

const countersLoaded = (data) => {
    return {
        type: 'FETCH_COUNTERS_SUCCESS',
        payload: data
    };
};

const geolocationDetected = (data) => {
    return {
        type: 'GEOLOCATION_DETECTED',
        payload: data
    };
};

const usedUpdate = (data) => {
    return {
        type: 'USED_UPDATE',
    };
};


export const fetchPoints = (dispatch) => (...args) => {
    const apiService = new APIService();
    dispatch(pointsRequested());
    apiService.getPoints()
        .then((data) => dispatch(pointsLoaded(data)))
        .catch((err) => dispatch(pointsError(err)));
}

export const fetchCounters = (dispatch) => () => {
    const apiService = new APIService();
    apiService.getCounters()
        .then((data) => dispatch(countersLoaded(data)))
        .catch((err) => console.log(err));
};

export const checkInUser = (dispatch) => (lat, lng) => {
    const apiService = new APIService();
    apiService.checkInUser(lat, lng)
        .then((data) => {
            const cookies = new Cookies();
            cookies.set('checkin', 'true', { path: '/' });
            dispatch(usedUpdate())

            fetchCounters()

        })
        .catch((err) => console.log(err));
};

export const checkUsedStatus = (dispatch) => () => {
    const cookies = new Cookies();

    if (cookies.get('checkin') === "true") {
        dispatch(usedUpdate());
    }
}

export const saveGeolocation = (dispatch) => (lat, lng) => {
    dispatch(geolocationDetected({lat: lat, lng: lng}));
}
